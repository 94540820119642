const bannedStatesHindi = [
  'असम',
  'आंध्र प्रदेश',
  'नागालैंड',
  'तेलंगाना',
  'सिक्किम',
  'ओडिशा',
]

const bannedStates = [
  'Assam',
  'Andhra Pradesh',
  'Nagaland',
  'Telangana',
  'Sikkim',
  'Odisha',
]

export const bannedStatesString = (() => {
  const states = bannedStates
  const lastState = states.pop()
  return `${states.join(', ')}, and ${lastState}`
})()

export const bannedStatesStringHindi = (() => {
  const states = bannedStatesHindi
  const lastState = states.pop()
  return `${states.join(', ')}, और ${lastState}`
})()

export default bannedStates
