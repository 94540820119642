import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import './FAQ.scss'

interface Props {
  question: string | null
  answer?: string[]
  answerElement?: React.ReactNode
  color?: string
  answerBg?: string
  questinNumber: number
  expanded: boolean
  setExpanded(n: number): void
  hideButtons?: boolean
}

const FAQ: React.FC<Props> = ({
  question,
  answer,
  answerElement,
  color = '#000',
  answerBg,
  questinNumber,
  expanded,
  setExpanded,
  hideButtons,
}) => {
  const data = useStaticQuery(graphql`
    {
      plus: file(relativePath: { eq: "plus-bronze.png" }) {
        childImageSharp {
          gatsbyImageData(width: 10, layout: CONSTRAINED)
        }
      }
      minus: file(relativePath: { eq: "minus-bronze.png" }) {
        childImageSharp {
          gatsbyImageData(width: 10, layout: CONSTRAINED)
        }
      }
    }
  `)

  let answerStyles: React.CSSProperties = {}
  if (answerBg) {
    answerStyles = {
      background: answerBg,
      padding: '10px 10px 1px',
      borderRadius: '8px',
    }
  }

  return (
    <div className="faq">
      <button
        type="button"
        className={`q ${expanded ? 'expand' : 'og'}`}
        onClick={() => {
          setExpanded(questinNumber)
        }}
      >
        <h3 style={{ color }}>{question}</h3>
        {!hideButtons && (
          <GatsbyImage
            image={
              data[expanded ? 'minus' : 'plus'].childImageSharp.gatsbyImageData
            }
            alt="expand"
            className={`plus ${expanded ? 'minusIcon' : 'plusIcon'}`}
            imgStyle={{ objectFit: 'contain' }}
          />
        )}
      </button>
      {expanded ? (
        <div className="a" style={answerStyles}>
          {!answerElement &&
            answer?.map(a => {
              return (
                <div
                  className="answer-texts"
                  key={a}
                  dangerouslySetInnerHTML={{ __html: a }}
                />
              )
            })}
          {answerElement ? <>{answerElement}</> : null}
        </div>
      ) : null}
    </div>
  )
}

export default FAQ
