import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import FullscreenVideo from '../FullscreenVideo/FullscreenVideo'

interface Props {
  videoId: string
  bg?: string
}

const DEFAULT_THUMBNAIL = 'https://i.vimeocdn.com/video/989528548_480.jpg'

const VideoContainer: React.FC<Props> = ({
  videoId,
  bg = DEFAULT_THUMBNAIL,
}) => {
  const [showVideo, setShowVideo] = useState(false)
  const src = `https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0&autoplay=1`
  const style: React.CSSProperties = {
    background: `url(${bg})`,
    backgroundSize: '101%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }

  return (
    <>
      <div
        className="embed-container"
        onClick={() => {
          setShowVideo(true)
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') setShowVideo(true)
        }}
        role="button"
        tabIndex={0}
        style={style}
      >
        <div className="play-div">
          <StaticImage
            loading="eager"
            src="../../images/play-circle.png"
            alt="play"
            className="play"
            width={120}
          />
        </div>
      </div>

      {showVideo && (
        <FullscreenVideo
          src={src}
          close={() => {
            setShowVideo(false)
          }}
        />
      )}
    </>
  )
}

export default VideoContainer
