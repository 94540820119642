import { useState } from 'react'

const useFaqExpansion = () => {
  const [expandedFaq, set] = useState(-1)

  const setExpandedFaq = (qn: number) => {
    if (qn === expandedFaq) set(-1)
    else set(qn)
  }

  return { expandedFaq, setExpandedFaq }
}

export default useFaqExpansion
