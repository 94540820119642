import React, { useEffect } from 'react'
import { Link } from 'gatsby'

import { Col, Container, Row } from 'react-bootstrap'
import { Faq } from './faqs-list-interface'
import FAQ from './FAQ'
import * as links from '../internal-links'
import useFaqExpansion from '../../hooks/useFaqExpansion'
import './FAQs.scss'

interface Props {
  title?: string
  index?: number
  faqs: Faq[]
}

const faqLinks = [
  {
    title: 'General',
    link: links.FAQS,
  },
  {
    title: 'Card Games',
    link: links.FAQS_CARDS,
  },
  {
    title: 'Contests',
    link: links.FAQS_CONTESTS,
  },
  {
    title: 'Payments and Withdrawals',
    link: links.FAQS_PAYMENTS_WITHDRAWALS,
  },
]

const FAQs: React.FC<Props> = ({ title, index = 0, faqs }) => {
  useEffect(() => {
    const selectedIndex = faqLinks.findIndex(
      f => f.link === window.location.pathname
    )
    if (selectedIndex > 2) {
      const el = window.document.getElementById(`id${selectedIndex}`)
      const panel = window.document.getElementById('panel-container')

      if (el && panel) {
        // panel.scrollLeft = el.offsetLeft - 22
        panel.scrollTo({
          top: 0,
          left: el.offsetLeft - 22,
          behavior: 'smooth',
        })
      }
    }
  }, [])

  const { expandedFaq, setExpandedFaq } = useFaqExpansion()

  return (
    <div id="FAQs">
      <Container>
        <Row>
          <Col>
            <h1 className="page-title">
              {title ? (
                <>
                  <span>FAQs</span>
                  <span className="faq-title-desktop">{`: ${title}`}</span>
                </>
              ) : (
                <>
                  <span className="faq-title-mobile">FAQs</span>
                  <span className="faq-title-desktop">
                    Frequently Asked Questions
                  </span>
                </>
              )}
            </h1>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col id="side-panel" className="side-panel" md={3}>
            <div id="panel-container">
              {faqLinks.map((l, i) => {
                return (
                  <Link
                    to={l.link}
                    key={l.link}
                    className={i === index ? 'highlight' : ''}
                    id={`id${i}`}
                  >
                    {l.title}
                  </Link>
                )
              })}

              <Link to={links.HELP_CENTRE} className="btn btn-help">
                HELP CENTER
              </Link>
              <Link to={links.CONTACT_US} className="btn">
                CONTACT US
              </Link>
            </div>
          </Col>

          <Col className="faqs">
            {faqs.map((f, i) => {
              return (
                <React.Fragment key={f.question}>
                  <FAQ
                    question={f.question}
                    answer={f.answer}
                    answerElement={f.answerElement}
                    expanded={expandedFaq === i}
                    setExpanded={setExpandedFaq}
                    questinNumber={i}
                  />

                  {i !== faqs.length - 1 ? <div className="separator" /> : null}
                </React.Fragment>
              )
            })}
          </Col>
        </Row>

        <Row>
          <Col className="help" md={3}>
            <div>Couldn’t find what you were looking for ?</div>
            <Link to={links.HELP_CENTRE}>HELP CENTER</Link>
            <Link to={links.CONTACT_US}>CONTACT US</Link>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FAQs
